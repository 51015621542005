import axiosInstance from "../services/axiosIntercept";
import { useSelector } from "react-redux";
import * as selectors from "../selectors/selectors";
import { AxiosResponse } from "axios";
import { Account } from "../types";
import useGetOrganizations from "./getOrganizations";

type Payload = {
  userId: string;
  name: string;
  companyLogoUrl: null;
};

const useAddOrganization = () => {
  const getOrganizations = useGetOrganizations();
  const userId = useSelector(selectors.getId);

  const addOrganization = async (name: string): Promise<void> => {
    const payload: Payload = {
      userId,
      name,
      companyLogoUrl: null,
    };

    console.log("addOrganization payload", payload);

    return axiosInstance
      .post<any, AxiosResponse<Account>, Payload>(
        `${process.env.REACT_APP_API_URL}v1/account/create`,
        payload,
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        console.log(res.data);
        getOrganizations(); // Refresh the list
      });
  };

  return addOrganization;
};

export default useAddOrganization;
