import { AxiosResponse, isAxiosError } from "axios";
import axiosInstance from "./axiosIntercept";

export async function getMerchantIdFromEmailAddress(
  emailAddress: string,
): Promise<string | undefined> {
  try {
    const response = await axiosInstance.get<
      any,
      AxiosResponse<string | undefined>
    >(`${process.env.REACT_APP_API_URL}v2/business/getuuid/${emailAddress}`, {
      withCredentials: true,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error getting merchant UUID from email address :",
      isAxiosError(error) ? error.response : error,
    );
    return;
  }
}

type Payload = {
  email: string;
  deviceID: string;
  impersonatorEmail: string;
  turnstileToken: string;
};
type Response = {};
export async function getImpersonateOtp(
  payload: Payload,
): Promise<Response | undefined> {
  try {
    const response = await axiosInstance.post<
      any,
      AxiosResponse<Response>,
      Payload
    >(
      `${process.env.REACT_APP_API_URL}v4/auth/portal/impersonate/getOTP`,
      payload,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Error getting impersonate OTP : ${
        isAxiosError(error) ? error.response : error
      }`,
    );
  }
}
