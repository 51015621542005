import React, { useEffect, useRef, useState } from "react";
import { LookerEmbedSDK } from "@looker/embed-sdk";
import Dashboard from "../../Components/Layouts/Dashboard/dashboard";
import axiosInstance from "../../services/axiosIntercept";
import * as constants from "../../utils/constants";
import "./looker.css";
import {
  createDashboardURL,
  DASHBOARD_ID,
  DASHBOARD_URL,
} from "../../utils/constants";
import { useSelector } from "react-redux";
import {
  getAccountName,
  getAccountUuid,
  getIsLoadingVouchers,
  getSelectedProgramName,
} from "../../selectors/selectors";
import Menu from "../../Components/Looker/menu";
import Analytics from "../../Pages/Analytics/analytics"; // Import Analytics component
import LoadingSpinner from "../../Components/LoadingSpinner/loading_spinner";
import { useDispatch } from "react-redux";
import { setIsLoadingVouchers } from "../../actions/actions";

const LookerEmbed = ({ dashboardInfo }: { dashboardInfo: any }) => {
  const dashboardRef = useRef<HTMLDivElement>(null);
  const [embedUrl, setEmbedUrl] = useState("");
  const apiKey = process.env.REACT_APP_API_KEY;

  const programName = useSelector(getSelectedProgramName);
  const accountName = useSelector(getAccountName);
  const accountUuid = useSelector(getAccountUuid);

  useEffect(() => {
    if (
      dashboardInfo.dashboardId === null ||
      dashboardInfo.dashboardUrl === null
    ) {
      return;
    }

    const fetchEmbedUrl = async () => {
      try {
        if (!process.env.REACT_APP_API_URL) {
          throw new Error("API URL is not defined");
        }

        const dashboardUrl = await createDashboardURL(
          dashboardInfo.dashboardUrl,
          accountUuid,
          accountName,
          programName,
        );

        const response = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}v1/looker/signed-url`,
          {
            dashboardId: dashboardInfo.dashboardId,
            dashboardUrl: dashboardUrl,
          },
          {
            withCredentials: true,
            headers: {
              "api-key": apiKey,
              "Content-Type": "application/json",
            },
          },
        );

        if (response.data) {
          setEmbedUrl(response.data.signedUrl);
        } else {
          console.error("No embed URL found in response.");
        }
      } catch (error) {
        console.error("Error fetching embed URL:", error);
      }
    };
    // setEmbedUrl('https://meed.cloud.looker.com/login/embed/%2Fembed%2Fdashboards%2F24%3FOrganisation%2BName%3DPhil%2BIngram%26Program%2BName%3DGraceland%26Organisation%2BUUID%3D630379bb-a5da-4ce7-bb0d-b187dc848e18%26Date%2BRange%3D7%2Bday%26Location%3DMain%2BLocation?nonce=%22lcM6d17z9cY5Eoec%22&time=1726131557&session_length=1800&external_user_id=%2210%22&permissions=%5B%22see_user_dashboards%22%2C%22access_data%22%2C%22see_looks%22%5D&models=%5B%22meed_loyalty%22%5D&access_filters=%7B%7D&user_attributes=%7B%7D&force_logout_login=true&signature=aXSMz%2Bkxt3s8hXyJzzVd929zZoU%3D');
    fetchEmbedUrl();
  }, [dashboardInfo, apiKey]);

  useEffect(() => {
    if (
      embedUrl &&
      dashboardRef.current &&
      process.env.REACT_APP_LOOKER_EMBED_HOST
    ) {
      try {
        dashboardRef.current.innerHTML = "";
        LookerEmbedSDK.init(process.env.REACT_APP_LOOKER_EMBED_HOST, "/auth");

        LookerEmbedSDK.createDashboardWithUrl(embedUrl)
          .appendTo(dashboardRef.current)
          .withClassName("looker-dashboard")
          .on("dashboard:run:start", () => console.log("Dashboard run started"))
          .on("dashboard:run:complete", () =>
            console.log("Dashboard run completed"),
          )
          .build()
          .connect()
          .then(() => {
            console.log("Dashboard embedded successfully");
          })
          .catch((error) => {
            console.error("Error connecting the dashboard:", error);
          });
      } catch (error) {
        console.error("Error during Looker Embed SDK initialization:", error);
      }
    }
  }, [embedUrl]);

  return <div ref={dashboardRef}></div>;
};

function Looker() {
  const [dashboardInfo, setDashboardInfo] = useState<{
    dashboardId: number | null;
    dashboardUrl: string | null;
  }>({
    dashboardId: DASHBOARD_ID.OVERVIEW,
    dashboardUrl: DASHBOARD_URL.OVERVIEW,
  });

  const dispatch = useDispatch();

  const [showAnalytics, setShowAnalytics] = useState<boolean>(false);

  const isLoadingVouchers = useSelector(getIsLoadingVouchers);
  console.log("isLoadingVouchers:", isLoadingVouchers);

  // if (isLoadingVouchers) {
  //   return <LoadingSpinner />;
  // }

  const handleDashboardSelect = (id: number, url: string) => {
    setShowAnalytics(false); // Hide Analytics when a dashboard is selected
    setDashboardInfo({ dashboardId: id, dashboardUrl: url });
    dispatch(setIsLoadingVouchers(false));
    console.log("Selected Dashboard ID:", id);
    console.log("Selected Dashboard URL:", url);
  };

  const handleAnalyticsSelect = () => {
    setShowAnalytics(true); // Show Analytics page when selected
    setDashboardInfo({ dashboardId: null, dashboardUrl: null }); // Clear dashboard selection
  };

  console.log("Dashboard Info:", dashboardInfo);
  return (
    // <Dashboard>
    <div>
      {isLoadingVouchers && !showAnalytics ? (
        <LoadingSpinner />
      ) : (
        <>
          <Menu
            onDashboardSelect={handleDashboardSelect}
            onAnalyticsSelect={handleAnalyticsSelect}
          />
          <div className="dashboard-content">
            {showAnalytics ? (
              <Analytics />
            ) : (
              <LookerEmbed dashboardInfo={dashboardInfo} />
            )}
          </div>
        </>
      )}
    </div>

    // {/* </Dashboard> */}
  );
}

export default Looker;
