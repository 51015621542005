import axiosInstance from "../services/axiosIntercept";
import { useDispatch, useSelector } from "react-redux";
import * as selctors from "../selectors/selectors";
import * as actions from "../actions/actions";
import { AxiosResponse } from "axios";
import { AccountMembership, AccountAndUserRole } from "../types";

const useGetOrganizations = () => {
  const dispatch = useDispatch();
  const userUuid = useSelector(selctors.getUuid);

  const getOrganizations = async () => {
    try {
      const response = await axiosInstance.post<
        any,
        AxiosResponse<AccountMembership[]>,
        { userUuid: string }
      >(
        `${process.env.REACT_APP_API_URL}v2/account/list`,
        { userUuid: userUuid! },
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        },
      );

      const newAccounts: AccountAndUserRole[] = response.data.map(
        (membership) => ({
          account: membership.account,
          merchantUser: membership.merchantUser,
          userRole: membership.userRole,
        }),
      );

      dispatch(actions.setAccounts(newAccounts));
    } catch (err) {
      console.error("Error while getting organization list.", err);
    }
  };

  return getOrganizations;
};

export default useGetOrganizations;
