import React, { FormEventHandler, useState } from "react";
import "../VerifyName/verify_name_page.css";
import { warningWithTimeout } from "../../utils/helpers";
import AppInput from "../../Components/AppInput/app_input";
import Helper from "../../Components/Helper/helper";
import AppButton from "../../Components/AppButton/app_button";
import Popup from "../../Components/Popup/popup";

type Props = {
  onSave: (name: string) => Promise<void>;
  onCancel?: () => void;
};

const Organization_Name_Page: React.FC<Props> = ({ onSave, onCancel }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [name, setName] = useState("");
  const handleSubmit: FormEventHandler = async (e) => {
    setIsSubmitting(true);

    e.preventDefault();

    if (!name) {
      warningWithTimeout({
        message: "Please enter the organization/company name.",
      });
      return;
    } else if (name.length < 2) {
      warningWithTimeout({
        message:
          "The organization/company name must be at least 2 characters long.",
      });
      return;
    }

    console.log(name);

    try {
      // This component should not handle the saving itself because this component is reused in several places.
      // Need to let the parent of this component do the saving by calling onSave
      await onSave(name);
    } catch (err) {
      console.error("Error while adding the organization name.", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Popup disableOverlayClick onCloseClick={onCancel}>
      <form className="auth auth--set-name" onSubmit={handleSubmit}>
        <fieldset disabled={isSubmitting} className="verify-name-fieldset">
          <div className="auth__heading">
            What is your company or organization name?
          </div>
          <div className="auth__panel">
            <AppInput
              id="ver_code"
              name="ver_code"
              placeholder="Enter your organization name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              style={{ fontSize: "14px" }}
            />
            <Helper>
              Your organization name must contain at least 2 characters
            </Helper>
          </div>
          <div className="auth__submit">
            <AppButton
              fullWidth
              uniqueModifier="submit"
              type="submit"
              onClick={handleSubmit}
            >
              Next Step
            </AppButton>
          </div>
        </fieldset>
      </form>
    </Popup>
  );
};

export default Organization_Name_Page;
