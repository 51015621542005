import axiosInstance from "../services/axiosIntercept";
import { useSelector } from "react-redux";

const useUpdateEmail = () => {
  const updateEmail = (
    otp,
    pasetoToken,
    turnstileToken,
    // byPassCloudfareCheckCode,
  ) => {
    try {
      const payload = {
        otp: otp,
        pasetoToken: pasetoToken,
        turnstileToken: turnstileToken,
        // byPassCloudfareCheckCode: byPassCloudfareCheckCode,
      };

      console.log(payload);
      axiosInstance.post(
        `${process.env.REACT_APP_API_URL}v2/business/update/email`,
        payload,
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        },
      );
    } catch (err) {
      console.error("Error while sending email update request!.", err);
    }
  };
  return updateEmail;
};

export default useUpdateEmail;
