import React from "react";
import ReactDOM from "react-dom/client";

import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persist } from "./store"; // Assuming you've modified your store as suggested
import { datadogRum } from "@datadog/browser-rum";
import { APP_VERSION } from "./utils/constants";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

datadogRum.init({
  applicationId: "3d0d9233-1e8c-4714-a483-501e37115129",
  clientToken: "puba884ebeaa9ad46a6d22688c448b8c92a",
  site: "us5.datadoghq.com",
  service: "meed-portal",
  env: "production",
  // Specify a version number to identify the deployed version of your application in Datadog
  version: APP_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  allowFallbackToLocalStorage: true,
  defaultPrivacyLevel: "allow",
});

datadogRum.startSessionReplayRecording();

const root = ReactDOM.createRoot(document.getElementById("root")!); // Updated for React 18+

// Wrap your App component with the Provider and PersistGate
root.render(
  <PayPalScriptProvider
    options={{
      clientId: String(process.env.REACT_APP_PAYPAL_CLIENT_ID),
      vault: true,
      intent: "subscription",
    }}
  >
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persist}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </PayPalScriptProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
