import React, {
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
  ChangeEventHandler,
} from "react";
import ReactCrop, { Crop } from "react-image-crop";
import { Button, Modal } from "antd";
import "react-image-crop/dist/ReactCrop.css";
import { notification } from "antd";

function getCroppedImg(
  image: HTMLImageElement,
  crop: { x: number; y: number; width: number; height: number },
): Promise<string> {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleY;

  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Could not get Canvas 2D Context");
  }

  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    canvas.width,
    canvas.height,
  );

  return new Promise((resolve, reject) => {
    const base64 = canvas.toDataURL("image/jpeg", 0.9);
    if (!base64) {
      reject(new Error("Canvas is empty"));
      return;
    }
    resolve(base64);
  });
}

type ImageUploaderProps = {
  // A callback that is called when the image has been resized.
  onImageResized: ({
    src,
    filename,
  }: {
    src: string;
    filename?: string;
  }) => void;
  // The desired height of the uploaded image.
  height: number;
  // The desired width of the uploaded image.
  width: number;
  aspectRatio: number;
};
export type ImageUploaderMethods = {
  openFileUpload: () => void;
  clear: () => void;
};
const ImageUploader = forwardRef<ImageUploaderMethods, ImageUploaderProps>(
  ({ onImageResized, width, height, aspectRatio }, ref) => {
    const imgRef = useRef<HTMLImageElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [filename, setFilename] = useState<string>();
    const [src, setSrc] = useState<string>();
    const [crop, setCrop] = useState<Crop>();
    const [visible, setVisible] = useState(false);

    const onCancel = useCallback(() => {
      setVisible(false);
      setSrc(undefined);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }, []);

    const onImageLoaded = useCallback(
      (image: HTMLImageElement) => {
        if (image.naturalWidth < width || image.naturalHeight < height) {
          notification.warning({
            message: `Please select an image with size larger than ${width}x${height} pixels.`,
            description: `Selected image size is ${image.naturalWidth}x${image.naturalHeight} pixels.`,
          });
          onCancel();
          return;
        }

        const imageAspectRatio = image.naturalWidth / image.naturalHeight;
        const cropWidth =
          imageAspectRatio > aspectRatio
            ? image.height * aspectRatio
            : image.width;
        const cropHeight =
          imageAspectRatio > aspectRatio
            ? image.height
            : image.width / aspectRatio;

        setSrc(image.src);
        setVisible(true);

        setCrop({
          unit: "px",
          // aspect: aspectRatio,
          width: cropWidth,
          height: cropHeight,
          x: (image.width - cropWidth) / 2, // Centering the initial crop
          y: (image.height - cropHeight) / 2, // Centering the initial crop
        });
      },
      [width, height, aspectRatio],
    );

    const onSelectFile: ChangeEventHandler<HTMLInputElement> = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        const selectedFile = e.target.files[0];

        if (!selectedFile) {
          return;
        }

        // Check if the file size is less than 2 MB
        if (selectedFile.size > 1024 * 1024 * 2) {
          // alert("Please select an image smaller than 2 MB.");
          notification.warning({
            message: "Please upload an image less than 2MB",
            description: `Please try again.`,
          });
          return;
        }

        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setSrc(reader.result?.toString());
          setFilename(selectedFile.name);
          setVisible(true);
        });
        reader.readAsDataURL(selectedFile);
      }
    };

    const onCropComplete = useCallback(async () => {
      if (!crop) {
        return;
      }

      console.log(imgRef.current, crop.width, crop.height);
      if (imgRef.current && crop.width && crop.height) {
        const base64Image = await getCroppedImg(imgRef.current, crop);
        onImageResized({ src: base64Image, filename });
        setVisible(false);
      }
    }, [onImageResized, crop, filename]);

    useImperativeHandle(ref, () => ({
      openFileUpload: () => {
        if (inputRef.current) {
          inputRef.current.click();
        }
      },
      clear: () => {
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      },
    }));

    return (
      <div>
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          style={{ display: "none" }}
          ref={inputRef}
        />
        <Modal
          open={visible}
          onCancel={onCancel}
          footer={<Button onClick={onCropComplete}>Done</Button>}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {src && (
              <ReactCrop
                crop={crop}
                onChange={(newCrop) => {
                  setCrop(newCrop);
                }}
                aspect={aspectRatio}
              >
                <img
                  src={src}
                  onLoad={(e) => onImageLoaded(e.currentTarget)}
                  ref={imgRef}
                />
              </ReactCrop>
            )}
          </div>
        </Modal>
      </div>
    );
  },
);

export default ImageUploader;
