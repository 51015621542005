import { isAxiosError } from "axios";
import axiosInstance from "../services/axiosIntercept";

const useActivateWelcomeVoucher = () => {
  const activateWelcomeVoucher = async (
    programUuid: string,
    voucherCode: string,
  ) => {
    try {
      const response = await axiosInstance.post<any, any, any>(
        `${process.env.REACT_APP_API_URL}v1/voucher/activate/welcomeVoucher`,
        {
          voucherUuid: voucherCode,
          programUuid: programUuid,
        },
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        },
      );
      console.log("Welcome Voucher activated", response.data);

      return response.data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        console.error(
          "API Error:",
          error.response?.status,
          error.response?.data,
        );
      } else {
        console.error("Network Error:", error.message);
      }
      throw error;
    }
  };

  return activateWelcomeVoucher;
};

export default useActivateWelcomeVoucher;
