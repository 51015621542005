import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import useGetLogout from "./getLogout";
import * as selectors from "../selectors/selectors";
import { useSelector } from "react-redux";
import axiosInstance from "../services/axiosIntercept";
import { useCallback } from "react";

/**
 *
 * @param {string} programUuid
 * @returns {() => Promise<import("../types").ProgramLocation[]>}
 */
const useGetLocations = (programUuid) => {
  const getLocations = useCallback(async () => {
    if (!programUuid) {
      return [];
    }

    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}v1/business/location/` + programUuid,
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        },
      );
      console.log("Locations fetched", response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.status, error.response.data);
      } else {
        console.error("Network Error:", error.message);
      }
      throw error;
    }
  }, [programUuid]);

  return getLocations;
};

export default useGetLocations;
