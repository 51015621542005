import axiosInstance from "../services/axiosIntercept";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors/selectors";
import * as actions from "../actions/actions";

const useAddAccountName = () => {
  const dispatch = useDispatch();
  const accountUUID = useSelector(selectors.getAccountUuid);

  const addAccountName = async (/** @type {string} */ name) => {
    try {
      const payload = {
        name: name,
        uuid: accountUUID,
      };

      console.log("addAccountName payload", payload);

      await axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}v1/account/update/name`,
          payload,
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          console.log(res.data);
          dispatch(actions.setAccounts(res.data));
          dispatch(actions.setAccount(res.data[0]));
        })
        .catch((err) => {
          console.error("Error while adding the account name.", err);
        });
    } catch (err) {
      console.error("Error while adding the account name.", err);
    }
  };

  return addAccountName;
};

export default useAddAccountName;
