import React, { useEffect, useState } from "react";
import styles from "./editProfile.module.css";
import { notification } from "antd";
import Label from "../../Components/Label/label";
import AppInput from "../../Components/AppInput/app_input";
import Popup from "../../Components/Popup/popup";
// import { useSelector } from "react-redux";
import { getId } from "../../selectors/selectors";
import BackIcon from "../../assets/icons/back.svg";
import { set } from "react-hook-form";
import axios from "axios";
import { on } from "events";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions/actions";
import updateProfile from "../../Hooks/updateProfile";
import useUpdateProfile from "../../Hooks/updateProfile";
import useEmailUpdateRequest from "../../Hooks/emailUpdateRequest";

/**
 *
 * @param {object} props
 * @param {() => void} props.type
 * @param {() => void} props.data
 * @param {() => void} props.imgSha
 * @param {() => void} props.onCancel
 * @param {() => void} props.onSuccess
 * @returns {import("react").ReactNode}
 */
function EditProfile({ type, data, imgSha, onCancel, onSuccess }) {
  const merchantId = useSelector(getId);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [profileData, setProfileData] = useState(data);
  const [role, setRole] = useState("USER");

  const updateProfile = useUpdateProfile();
  const emailUpdateRequest = useEmailUpdateRequest();
  //   useEffect(() => {
  //     const emailRegex = /\S+@\S+\.\S+/;

  //     // Check if the inputEmail matches the emailRegex
  //     // setIsFormValid(emailRegex.test(email) && name !== "");
  //   }, [email, name]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    notification.info({
      message: "Updating merchant profile..",
      description: `Please wait...`,
    });

    try {
      if (type === "Email") {
        emailUpdateRequest(profileData);
        onSuccess();
        onCancel();
      } else if (type === "Name") {
        updateProfile(imgSha, profileData);
        onSuccess();
        onCancel();
      }

      notification.success({
        message: "Profile Updated",
        description: `Your profile has been updated`,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: `There was an error while updating your profile`,
      });
      onCancel();
    }
  };

  return (
    <Popup disableOverlayClick onCloseClick={onCancel}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <fieldset disabled={isSubmitting}>
          <div className={styles.title}>Personal Information</div>
          <div className={styles.subtitle}>
            <img
              src={BackIcon}
              alt="back"
              onClick={() => {
                onCancel();
              }}
              style={{ cursor: "pointer" }}
            />
            Edit {type}
          </div>

          {type === "Name" ? (
            <div className={styles.field}>
              <Label>Name</Label>
              <AppInput
                name="Name"
                placeholder="Enter Name"
                value={profileData}
                onChange={(e) => setProfileData(e.target.value)}
              />
            </div>
          ) : (
            <div className={styles.field}>
              <Label>Email</Label>
              <AppInput
                name="Email"
                placeholder="Enter Email"
                value={profileData}
                onChange={(e) => setProfileData(e.target.value)}
              />
            </div>
          )}

          <div className={styles.buttons}>
            <button type="submit" className="button" onClick={handleSubmit}>
              Confirm
            </button>
            <button
              type="button"
              className="button button--secondary button--full-width"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </button>
          </div>
        </fieldset>
      </form>
    </Popup>
  );
}

export default EditProfile;
