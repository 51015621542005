import React, { useState, useEffect, ChangeEventHandler } from "react";
import { Modal, Input, notification } from "antd";
import { v4 as uuidv4 } from "uuid";
import "./LocationCreationModal.css";
import Uploader from "../Uploader/uploader";
import AppButton from "../AppButton/app_button";
import useUpdateLocation from "../../Hooks/updateLocation";
import { ProgramLocation } from "../../types";
import useAddLocation from "../../Hooks/addLocation";
import { useShowTierLimitReachedWarning } from "../../utils/utils";
const { TextArea } = Input;

type Props = {
  handleCreate: (location: ProgramLocation) => Promise<void>;
  handleUpdate: (location: ProgramLocation) => Promise<void>;
  handleCancel: () => void;
  programUuid?: string;
  data?: ProgramLocation;
};
const LocationCreationModal: React.FC<Props> = ({
  handleCreate,
  handleUpdate,
  handleCancel,
  programUuid,
  data,
}) => {
  const updateLocation = useUpdateLocation();
  const addLocation = useAddLocation();
  const [location, setLocation] = useState<ProgramLocation>({
    address: "",
    businessName: "",
    locationName: "",
    logoImageId: "",
    id: "",
  });
  const showTierLocationsLimitReachedWarning =
    useShowTierLimitReachedWarning("location");

  useEffect(() => {
    if (data) {
      setLocation(data);
    }
  }, [data]);

  const handleInputChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setLocation((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSave = async () => {
    console.log("location", location);
    if (!location?.businessName) {
      notification.warning({
        message: "Please enter the location business name",
      });
      return;
    } else if (!location?.locationName) {
      notification.warning({
        message: "Please enter the location label",
      });
      return;
    } else if (!location?.address) {
      notification.warning({
        message: "Please enter the location address",
      });
      return;
    } else if (!location?.logoImageId) {
      notification.warning({
        message: "Please pick the location logo image",
      });
      return;
    }

    if (!location.id) {
      // Create mode.

      try {
        // If program id is not present, then we're in create program mode. Pass the new locations to the parent and let it handle the saving.
        if (!programUuid) {
          handleCreate({
            ...location,
            id: `_${uuidv4()}`,
          });
        } else {
          // program id is present, we're currently editing a program. Save new locations right away.
          const newLocation = await addLocation(programUuid, location);
          handleCreate(newLocation);
        }
      } catch (error) {
        console.log(error);
        showTierLocationsLimitReachedWarning();
        return;
      }

      setLocation({
        address: "",
        businessName: "",
        locationName: "",
        logoImageId: "",
        id: "",
      });
      return;
    }

    if (String(location.id).startsWith("_")) {
      // Editing an unsaved location (id is not present)
      handleUpdate(location);
    }

    try {
      // Editing a saved location (id is present)
      const newLocation = await updateLocation(location.id, location);
      console.log("location", newLocation);
      setLocation(newLocation);
      handleUpdate(newLocation);
    } catch (error) {
      console.error("Failed to update location:", error);
    }
  };

  const title = data?.id ? "Edit Location" : "Enter new Location";

  return (
    <Modal
      open
      title={title}
      onOk={handleSave}
      onCancel={handleCancel}
      width={378}
      footer={null}
    >
      <div className="locations-creation-container">
        <p className="locations-creation-input-title" style={{ marginTop: 10 }}>
          Business Name
        </p>
        <Input
          name="businessName"
          placeholder="Your Business Name"
          value={location.businessName}
          onChange={handleInputChange}
        ></Input>
        <p className="locations-creation-input-title">Location Label</p>
        <Input
          name="locationName"
          placeholder="Your Location Label"
          value={location.locationName} // Change this line
          onChange={handleInputChange}
        ></Input>
        <p className="locations-creation-input-title">Address</p>
        <TextArea
          name="address"
          placeholder="Your Location Address"
          showCount
          style={{ height: 70 }}
          value={location.address}
          onChange={handleInputChange}
        ></TextArea>
        <p className="locations-creation-input-title">Location logo</p>
        <Uploader
          type="image"
          message="Upload your Location logo"
          width={315}
          height={315}
          defaultImageSrc={""}
          onUploaded={(sha) => {
            setLocation((state) => ({
              ...state,
              logoImageId: sha,
            }));
          }}
          aspectRatio={1}
        />
        <p style={{ color: "#5D6164", fontSize: "12px", marginTop: "0" }}>
          Image size must be below 2MB
        </p>
        <AppButton
          type="primary"
          className="create-location-save-btn"
          disabled={!location.logoImageId}
          onClick={handleSave}
        >
          Save
        </AppButton>
      </div>
    </Modal>
  );
};

export default LocationCreationModal;
