import axiosInstance from "./axiosIntercept";

export const getEmailList = async (/** @type {any} */ _uuid) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}v1/program/emailOptIn/sendMail/${_uuid}`,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    console.log("sent member list: ", response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("API Error:", error.response.status, error.response.data);
    } else {
      console.error("Network Error:", error.message);
    }
    throw error;
  }
};
